<template>
  <v-dialog
    v-if="mostrar"
    :value="mostrar"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar
        color="grey lighten-3"
        flat
        dense
      >
        <v-toolbar-title class="font-weight-black">
          Perfil do veículo #{{ placa }}
        </v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary" />
            <v-tab>
              Frequência
            </v-tab>
            <v-tab>
              Entrada/saída
            </v-tab>
            <v-tab>
              Permanência
            </v-tab>
            <v-tab>
              Calendário
            </v-tab>
          </v-tabs>
        </template>

        <v-spacer />
        <v-toolbar-items>
          <v-btn
            text
            small
            @click="$emit('fechar')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-tabs-items
        v-model="tab"
        style="min-height: 340px"
      >
        <v-tab-item>
          <perfil-veiculo-frequencia
            :placa="placa"
          />
        </v-tab-item>
        <v-tab-item>
          <perfil-veiculo-entrada-saida
            :placa="placa"
          />
        </v-tab-item>
        <v-tab-item>
          <perfil-veiculo-permanencia
            :placa="placa"
          />
        </v-tab-item>
        <v-tab-item>
          <perfil-veiculo-calendario
            :placa="placa"
          />
        </v-tab-item>
        <v-tab-item>
          Rota de saída
        </v-tab-item>
        <v-tab-item>
          Rota de entrada
        </v-tab-item>
        <v-tab-item>
          <perfil-veiculo-passagens-por-dia-horario />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {

    components: {
      PerfilVeiculoEntradaSaida: () => import('@/components/dialog/perfil-veiculo/DialogPerfilVeiculoEntradaSaida'),
      PerfilVeiculoFrequencia: () => import('@/components/dialog/perfil-veiculo/DialogPerfilVeiculoFrequencia'),
      PerfilVeiculoPermanencia: () => import('@/components/dialog/perfil-veiculo/DialogPerfilVeiculoPermanencia'),
      PerfilVeiculoPassagensPorDiaHorario: () => import('@/components/dialog/perfil-veiculo/DialogPerfilVeiculoPassagensPorDiaHorario'),
      PerfilVeiculoCalendario: () => import('@/components/dialog/perfil-veiculo/DialogPerfilVeiculoCalendario'),
    },

    props: {
      mostrar: {
        type: Boolean,
        default: false,
      },
      placa: {
        type: String,
        default: '',
      },
    },

    data () {
      return {
        tab: null,
      }
    },
  }
</script>
